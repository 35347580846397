import React from "react";
import { Container } from "react-bootstrap";
import "./PrivacyPolicy.css";

function PrivacyPolicy() {
  return (
    <>
      <section className="privacy_section">
        <Container>
          <div className="privacy_main">
            <div className="title_div mb-4">
              <h2 className="title">Privacy Policy</h2>
            </div>
            <div className="mb-5 pb-3">
              <p className="sub_title">Our Commitment To You</p>
              <p className="privcytxt">
                Rexitaxi Private Limited is committed to protecting the privacy
                of our users. This Privacy Policy explains how we collect, use,
                and share information about you when you use our mobile
                application and our website.
              </p>
            </div>

            <div className="mb-4">
              <p className="sub_title">1. Information We Collect</p>
            </div>
            <div>
              <p className="sub_title">1.1 Information You Provide to Us</p>

              <div className="d-flex ms-5">
                <p className="text me-2">a.)</p>
                <p className="text">
                  <b>Account Information: </b>When you create an account, we may
                  collect your name, email address, phone number, and other
                  personal information. If you register using social media
                  accounts, we may collect information from your social media
                  profile, including your name, profile picture, and friend
                  list.
                </p>
              </div>
              <div className="d-flex ms-5">
                <p className="text me-2">b.) </p>
                <p className="text">
                  <b>Payment Information: </b>If you make a purchase through the
                  App, we may collect payment information, including
                  credit/debit card details, billing address, and transaction
                  history.
                </p>
              </div>
              <div className="d-flex ms-5 mb-4">
                <p className="text me-2">c.) </p>
                <p className="text">
                  <b>User Content: </b> Any content you upload, post, or
                  transmit through the App, including messages, photos, and
                  reviews.
                </p>
              </div>
              <div className="d-flex ms-5 mb-4">
                <p className="text me-2">d.) </p>
                <p className="text">
                  <b>Identity Verification Information: </b>For security
                  purposes, we may collect additional verification information,
                  such as a government-issued ID or selfie.
                </p>
              </div>
            </div>

            <div>
              <p className="sub_title">
                1.2 Information We Automatically Collect
              </p>
              <div className="d-flex ms-5">
                <p className="text me-2">a.) </p>
                <p className="text">
                  <b>Usage Data: </b>We collect information about your
                  interactions with the App, such as the pages or content you
                  view, your search queries, ride history, and other actions.
                </p>
              </div>
              <div className="d-flex ms-5">
                <p className="text me-2">b.) </p>
                <p className="text">
                  <b>Device Information: </b>We may collect information about
                  your device, including your IP address, device ID, operating
                  system, and browser type.
                </p>
              </div>
              <div className="d-flex ms-5 mb-4">
                <p className="text me-2">c.) </p>
                <p className="text">
                  <b>Location Information: </b>With your consent, we may collect
                  precise location data to provide location-based services, such
                  as matching you with nearby drivers.
                </p>
              </div>
            </div>

            <div>
              <p className="sub_title">1.3 Information from Third Parties</p>
              <div className="d-flex ms-5">
                <p className="text me-2">a.) </p>
                <p className="text">
                  <b>Driver Information: </b>If you use our App as a driver, we
                  may collect information from background check providers,
                  driving records, and criminal records where applicable by law.
                </p>
              </div>
              <div className="d-flex ms-5 mb-4">
                <p className="text me-2">b.) </p>
                <p className="text">
                  <b>Referrals and Social Connections: </b>If someone refers you
                  to our services, we may collect information about you from
                  that person.
                </p>
              </div>
            </div>

            <div class="mb-5">
              <p className="sub_title">2. How We Use Your Information</p>
              <div className="ms-5">
                <p className="text">
                  <b>
                    We use the information we collect for various purposes,
                    including:
                  </b>
                </p>
                <p className="text">
                  - To provide, maintain, and improve the App and our services.
                </p>
                <p className="text">
                  - To facilitate ride bookings and payments.
                </p>
                <p className="text">- To personalize your experience.</p>
                <p className="text">
                  - To verify your identity and ensure safety and security.
                </p>
                <p className="text">
                  - To communicate with you and send updates.
                </p>
                <p className="text">- To monitor and analyze usage trends.</p>
                <p className="text">- To comply with legal obligations.</p>
              </div>
            </div>

            <div class="mb-5">
              <p className="sub_title">3. How We Share Your Information</p>
              <div className="ms-5">
                <p className="text">
                  <b>
                    We may share your information in the following
                    circumstances:
                  </b>
                </p>
                <p className="text">
                  - With Service Providers and Business Partners.
                </p>
                <p className="text">
                  - With Drivers and Riders for ride-related information.
                </p>
                <p className="text">
                  - For Legal Reasons and in connection with a sale or merger.
                </p>
              </div>
            </div>

            <div class="mb-5">
              <p className="sub_title">4. Your Rights and Choices</p>
              <div className="ms-5">
                <p className="text">
                  - You may update, correct, or delete your account information
                  at any time by accessing your account settings.
                </p>
                <p className="text">
                  - You can disable location services on your device, but this
                  may affect certain features of the App.
                </p>
                <p className="text">
                  - You can opt-out of receiving promotional emails by following
                  the unsubscribe instructions in the emails or contacting us.
                </p>
              </div>
            </div>

            <div class="mb-5">
              <p className="sub_title">5. Security</p>
              <div className="ms-5">
                <p className="text">
                  We take reasonable measures to protect your information, but
                  no method of transmission over the internet is completely
                  secure.
                </p>
              </div>
            </div>

            <div class="mb-5">
              <p className="sub_title">6. Children's and Teenagers' Privacy</p>
              <div className="ms-5">
                <p className="text">
                  Our App is not intended for use by children under the age of
                  13. For teenagers aged 13 to 18, we recommend parental
                  permission before using our services.
                </p>
              </div>
            </div>

            <div class="mb-5">
              <p className="sub_title">7. Changes to This Privacy Policy</p>
              <div className="ms-5">
                <p className="text">
                  We may update this Privacy Policy from time to time, and any
                  changes will be posted on our website or app.
                </p>
              </div>
            </div>

            <div class="mb-5">
              <p className="sub_title">8. Contact Us</p>
              <div className="ms-5">
                <p className="text">
                  <b>
                    If you have any questions or concerns, please contact us at:
                  </b>
                </p>
                <p className="text">
                  Rexitaxi Private Limited
                </p>
                <p className="text">
                  [116/367 Adarsh Nagar, Rawatpur Gaon, Kanpur]
                </p>
                <p className="text">
                  <b>Email: </b>
                  vermasuresh6412@gmail.com
                </p>
                <p className="text">
                  <b>Phone: </b> <a>+91 63940 17085</a>
                </p>
              </div>
            </div>
          </div>
        </Container>
        <div className="privacy_overlay"></div>
      </section>
    </>
  );
}

export default PrivacyPolicy;
