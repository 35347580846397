import React from 'react'
import './OurPresence.css'
import { Col, Container, Row } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCity, faCloudArrowDown, faUser } from '@fortawesome/free-solid-svg-icons'
function OurPresence() {
    return (
        <>
            <section className='ourpresence_section'>
                <Container>
                    <div className='ourpresence_divmain'>
                        <Row>
                            <Col xxl={6} xl={7} lg={9} md={10} sm={11} className='mx-auto'>


                                <h2 className='title text-center'>Our Presence</h2>
                                <p className='text text-center'>Our presence has revolutionized intra-city travel, making it both affordable and efficient for everyone to achieve seamless last-mile connectivity.</p>

                                <div className='present_imagediv mb-3'>
                                    <img src={process.env.PUBLIC_URL + "/assets/images/homepage/mobilemockups.png"} className="presenttimg" />
                                </div>

                                <div className='contdivv'>
                                    <div className='subconttdiv'>
                                        <div>
                                            <div className='radiusdiv mb-2'>
                                                <div className='unerradiusdiv'>
                                                    <FontAwesomeIcon icon={faCloudArrowDown} />
                                                </div>
                                            </div>
                                            <p className='text text-center'>15 M <br/> Downloads</p>
                                        </div>
                                        <div>
                                            <div className='radiusdiv mb-2'>
                                                <div className='unerradiusdiv'>
                                                    <FontAwesomeIcon icon={faCity} />
                                                </div>
                                            </div>
                                            <p className='text text-center'>100+ <br/> Cities</p>
                                        </div>
                                        <div>
                                            <div className='radiusdiv mb-2'>
                                                <div className='unerradiusdiv'>
                                                    <FontAwesomeIcon icon={faUser} />
                                                </div>
                                            </div>
                                            <p className='text text-center'>900+ <br/> Riders</p>
                                        </div>
                                        <div>
                                            <div className='radiusdiv mb-2'>
                                                <div className='unerradiusdiv'>
                                                    <FontAwesomeIcon icon={faCloudArrowDown} />
                                                </div>
                                            </div>
                                            <p className='text text-center'>20 M <br/> Custmers</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='stripdiv'>
                                    <img src={process.env.PUBLIC_URL + "/assets/images/homepage/strip.png"} className="stripimg" />
                                </div>

                            </Col>
                        </Row>
                    </div>
                </Container>

                <div className='presentbacimg_div'>
                <img src={process.env.PUBLIC_URL + "/assets/images/homepage/OurPresence_background.png"} className="presentbackimg" />
                </div>
            </section>
        </>
    )
}

export default OurPresence