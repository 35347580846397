import React, { useState } from "react";
import "./Header.css";
import { Container, Nav, Navbar } from "react-bootstrap";
import Common_Button from "../CommonComponents/Button_Common/Button_Common";
import { Link, useNavigate } from "react-router-dom";
import { faEnvelope, faHeadphonesSimple } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookF, faInstagram, faYoutube } from "@fortawesome/free-brands-svg-icons";

function Header() {
  const [expanded, setExpanded] = useState(false);
  const navigate = useNavigate();

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    console.log(section);
    if (section) {
      section.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  const handleNavigateAndScroll = (e, sectionId) => {
    e.preventDefault();
    setExpanded(false); // Close the navbar
    navigate('/');
    setTimeout(() => {
      scrollToSection(sectionId);
    }, 100); // Adjust the delay if necessary
  };

  return (
    <>
      <section className='nav_section'>
        <Container>
          <div className="">
            <Navbar expanded={expanded} expand="lg" className="">
              <Navbar.Brand href="#">
                <div className="navimg_div">
                  <img src={process.env.PUBLIC_URL + "/assets/images/logos/rexilogo.png"} className="navimg" />
                </div>
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="navbarScroll" onClick={() => setExpanded(expanded ? false : "expanded")} />
              <Navbar.Collapse id="navbarScroll">
                <Nav className="navvvlinkss mx-auto my-2">
                  <Nav.Link href="/" onClick={() => setExpanded(false)}>Home</Nav.Link>
                  <Nav.Link href="/aboutus" onClick={() => setExpanded(false)}>About Us</Nav.Link>
                  <Nav.Link onClick={(e) => handleNavigateAndScroll(e, 'section1')}>Our Presence</Nav.Link>
                  <Nav.Link onClick={(e) => handleNavigateAndScroll(e, 'section2')}>Why Choose Us</Nav.Link>
                </Nav>
                <div>
                  <Link to="/contactus" onClick={() => setExpanded(false)}>
                    <Common_Button text="Contact Us" className="navbutton" />
                  </Link>
                </div>
              </Navbar.Collapse>
            </Navbar>
          </div>
        </Container>
      </section>
    </>
  );
}

export default Header;
