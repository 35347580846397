import React, { useState } from 'react'
import './StopWaiting.css'
import { Col, Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom';
function StopWaiting({csss}) {

    const [isHovered, setIsHovered] = useState(false);
    return (
        <>
            <section className='stopwaitng_section'
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            
            >
                <Container>
                    <div className='stopwaiting_main_div'>

                        <Row>
                            <Col xxl={8} xl={8} lg={7} className='pe-xl-0'>
                                <div className='stopwaimgdiv'>
                                    <div
                                        className='autowalaimgdiv'
                                      
                                    >
                                        <img
                                            src={process.env.PUBLIC_URL + (isHovered ? "/assets/images/homepage/mapmob1.png" : "/assets/images/homepage/mid12.png")}
                                            className="mobileotowala"
                                            alt="Autowala"
                                        />
                                    </div>
                                    <div className='leftmobile_div'>
                                    <img
                                            src={process.env.PUBLIC_URL + "/assets/images/homepage/left12.png"}
                                            className="leftmobile"
                                            alt="Autowala"
                                        />
                                    </div>
                                    <div className='rightmobile_div'>
                                    <img
                                            src={process.env.PUBLIC_URL + "/assets/images/homepage/right12.png"}
                                            className="rightmobile"
                                            alt="Autowala"
                                        />
                                    </div>
                                    <div className='automobile_div'>
                                    <img
                                            src={process.env.PUBLIC_URL + "/assets/images/homepage/rexiauto.png"}
                                            className="automobile"
                                            alt="Autowala"
                                        />
                                    </div>
                                </div>
                            </Col>
                            <Col xxl={4} xl={4} lg={5} className='ps-xl-0'>
                                <div className='stopwatextdiv'>
                                    <div className='textmaindiv'>


                                        <div className='top_text_div'>
                                            <p className='stoptext mb-0'>STOP WAITING</p>
                                            <p className='title starttext'>START BOOKING</p>
                                        </div>
                                        <div className='teeext_div'>
                                            <p className='loremmmtext'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing & typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing & typesetting industry.</p>
                                        </div>
                                    </div>
                                    <div>
                                        <h2 className='title mb-4'>Download the App</h2>

                                        <div className="button_div">

                                        <Link to={"https://www.apple.com/in/app-store/"} target='blank'><button className="buttonclassess me-2"> <img src={process.env.PUBLIC_URL + "/assets/images/homepage/appstore.png"} className=" buttonimagess" /></button></Link>
                                           <Link to={"https://play.google.com/store/"} target='blank'> <button className="buttonclassess"> <img src={process.env.PUBLIC_URL + "/assets/images/homepage/gplay.png"} className=" buttonimagess" /></button></Link>
                                        </div>
                                    </div>

                                </div>

                            </Col>
                        </Row>

                    </div>

                </Container>

                <div className={csss}></div>


            </section>

        </>
    )
}

export default StopWaiting