import React, { useState } from "react";
import "./ContactUs.css";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Link } from "react-router-dom";
import Common_Button from "../CommonComponents/Button_Common/Button_Common";
import { faArrowLeft, faEnvelope, faLocationDot, faMapLocationDot, faMobile, faPhone } from "@fortawesome/free-solid-svg-icons";
import { faFacebookF, faLinkedinIn, faTwitter, faYoutube } from "@fortawesome/free-brands-svg-icons";
const ContactUs = () => {
  const [showForm, setShowForm] = useState(false);
  const [showBg, setShowBg] = useState(false);
  const [showContactTextHolder, setShowContactTextHolder] = useState(true);

  const handleMapTextClick = () => {
    setShowForm(true);
    setShowBg(true);
    setShowContactTextHolder(false);
  };

  const handleArrowClick = () => {
    setShowContactTextHolder(true);
    setShowForm(false);
    setShowBg(false);
  };
  return (
    <>
      <section className="contact-us">
        <div className="map-section">
          <div className="mapouter">
            <div className="gmap_canvas">
              <iframe
                className="gmap_iframe"
                frameborder="0"
                scrolling="no"
                marginheight="0"
                marginwidth="0"
                src="https://maps.google.com/maps?width=600&amp;height=400&amp;hl=en&amp;q=pune&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
              ></iframe>
              <a href="https://pdflist.com/" alt="pdflist.com"></a>
            </div>
          </div>
        </div>
        {/* <div className="contact_form_holder "> */}
        <div
          className={`contact_form_holder ${showBg ? "contact_form_bg" : ""}`}
        >
          <div className="container">
            <div className="row justify-content-end">
              <div className="col-lg-4 col-md-4">
                <div
                  className={`contact-text-holder ${showContactTextHolder ? "" : "d-none"
                    }`}
                >
                  <div className="heading-holder mb-4">
                    <h2 className="title">Contact us</h2>
                  </div>
                  <div className="d-flex mb-3">
                  <div className="icon-holder me-3">
                      <FontAwesomeIcon
                        className="call-icon"
                        icon={faPhone}
                      />
                    </div>
                    <div className="address-holder">
                      <p>+91 9876543210</p>
                    </div>
                  </div>
                  <div className="d-flex mb-3">
                    <div className="icon-holder me-3">
                      {/* <FontAwesomeIcon
                        className="call-icon"
                        icon="fa-solid fa-envelope"
                      /> */}
                      <FontAwesomeIcon className="call-icon" icon={faEnvelope} />
                    </div>
                    <div className="address-holder">
                      <p>supportrexi@gmail.com</p>
                    </div>
                  </div>

                  <div className="d-flex">
                    <div className="icon-holder me-3">
                      {/* <FontAwesomeIcon
                        className="call-icon"
                        icon="fa-solid fa-location-dot"
                      /> */}
                       <FontAwesomeIcon  className="call-icon" icon={faLocationDot} />
                    </div>
                    <div className="address-holder">
                      <p>Akshya Nagar 1st Block 1st Cross, Rammurthy nagar, Pune - 411038</p>
                    </div>
                  </div>

                  <div className="d-flex">
                    <div className="icon-holder me-3">
                      {/* <FontAwesomeIcon
                        className="call-icon"
                        icon="fa-solid fa-map-location-dot"
                      /> */}
                      <FontAwesomeIcon  className="call-icon" icon={faMapLocationDot} />
                    </div>
                    <div
                      className="address-holder map-text"
                      onClick={handleMapTextClick}
                    >
                      <p>Find Us on Map</p>
                    </div>
                  </div>

                  <div className="border-holder  my-4"></div>
                  <div className="social-icon">
                  <div className="icon-holder me-3">
                      <FontAwesomeIcon className="call-icon" icon={faMobile} />
                     
                    </div>
                    <Link to="https://www.facebook.com/" target="blank">  <div className="icon-holder me-2">
                      
                      <FontAwesomeIcon className="brand-icon" icon={faFacebookF} />
                    </div></Link>
                    <Link to="https://in.linkedin.com/" target="blank">  <div className="icon-holder me-2">
                      <FontAwesomeIcon className="brand-icon" icon={faLinkedinIn} />
                    </div></Link>
                    <Link to="https://x.com/" target="blank"><div className="icon-holder me-2">
                      <FontAwesomeIcon className="brand-icon" icon={faTwitter} />
                    </div></Link>
                    <Link to="https://www.youtube.com/" target="blank">  <div className="icon-holder me-2">
                      <FontAwesomeIcon className="brand-icon" icon={faYoutube} />
                    </div></Link>
                    {/* <img
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/Image/contact/facebook.png"
                      }
                      className="facebook-icon me-2"
                    />
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/Image/contact/linkedin.png"
                      }
                      className="facebook-icon me-2"
                    />
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/Image/contact/pinterest.png"
                      }
                      className="facebook-icon me-2"
                    />
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/Image/contact/twitter.png"
                      }
                      className="facebook-icon"
                    /> */}
                  </div>
                </div>
              </div>
              <div
                className={`${showForm
                  ? "col-xl-5 col-lg-7 col-12 scale-down-hor-right"
                  : "col-lg-8 col-md-8 scale-up-hor-right"
                  }`}
              >
                <div className="form-holder ">
                  <div
                    className={`arrow-holder ${showForm ? "arrow-holder" : "d-none"
                      }`}
                    onClick={handleArrowClick}
                  >
                    <div className="left-arrow">

                    
                    <FontAwesomeIcon    icon={faArrowLeft} />
                    </div>
                    {/* <img
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/Image/Icon/arrow-left.png"
                      }
                      className="left-arrow"
                    /> */}
                  </div>
                  <Form>
                    <Row className="form_container">
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Control type="text" placeholder="Your name" />
                      </Form.Group>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1 "
                      >
                        <Form.Control type="email" placeholder="Email" />
                      </Form.Group>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Control type="text" placeholder="Subject" />
                      </Form.Group>

                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlTextarea1"
                      >
                        <Form.Control
                          as="textarea"
                          className="text-holder"
                          placeholder="Your message"
                          rows={3}
                        />
                      </Form.Group>
                      <div className="btn-holder mt-4">
                        <Common_Button
                          className="SubmitBtn"
                          text="Send Message"
                         
                        />
                      </div>
                    </Row>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ContactUs;