import React from 'react'
import './WhyChoose.css'
import { Col, Container, Row } from 'react-bootstrap'
function WhyChoose() {
    const data = [

        {
            name: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
            title: "RAPID CITY TRANSFER",
            image: process.env.PUBLIC_URL + " /assets/images/homepage/Objects.png",

        },
        {
            name: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
            title: "SAFETY GUARANTEE",
            image: process.env.PUBLIC_URL + "/assets/images/homepage/sheild.png",

        },
        {
            name: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
            title: "PRO CLEARED DRIVERS",
            image: process.env.PUBLIC_URL + "/assets/images/homepage/car.png  ",

        },
    ]
    return (
        <>
            <section className='whychoose_section'>
                <Container>
                    <div className='whychoose_main_div'>
                        <div className='title_div'>
                            <h2 className='title text-center'> <span className='whychooose'>Why Choose </span><img src={process.env.PUBLIC_URL + "/assets/images/logos/rexilogo.png"} className="titleimg" /></h2>
                        </div>
                        <Row>
                            <Col xxl={8} xl={9} lg={10} md={10} sm={11} className='mx-auto'>
                                <div className="cardddiv">
                                    <Row>
                                        {data.map((item, index) => (
                                            <Col xxl={4} xl={4} lg={4} md={6} sm={6}  className='mb-4 mx-auto'>
                                                <div className='craddmain'>
                                                    <p className='choosetext text-center'>{item.title}</p>
                                                    <p className='choosubtext text-center mb-0'>{item.name}</p>
                                                    <div className='topdivv'>
                                                        <img src={item.image} className=" topdivv_img" />
                                                    </div>
                                                </div>
                                            </Col>
                                        ))}
                                    </Row>
                                </div>
                            </Col>
                        </Row>

                    </div>
                </Container>
            </section>
        </>
    )
}

export default WhyChoose