import "./banner/Banner.css";
import Banner from "./banner/Banner";
import Conveince from "./Conveince/Conveince";
import OurPresence from "./OurPresence/OurPresence";
import WhyChoose from "./WhyChoose/WhyChoose";
import HowWeWork from "./HowWeWork/HowWeWork";
import StopWaiting from "./StopWaiting/StopWaiting";


function Home() {

 
  return (
    <>
      <Banner />  
      <Conveince/>  
      <div id="section1">
        <OurPresence />
      </div>
      <div id="section2">
      <WhyChoose/>
      </div>
      <HowWeWork/>
      <StopWaiting/>
    </>
  );
}

export default Home;
