import React from 'react'
import './Conveince.css'
import { Col, Container, Row } from 'react-bootstrap'
import Common_Button from '../../CommonComponents/Button_Common/Button_Common'
import { Link } from 'react-router-dom'
function Conveince() {
    return (
        <>

            <section className='conveince_section'>
                <Container>
                    <div className='conveince_main'>
                        <Row>
                            <Col lg={6} className='mb-3'>
                                <div className='conenve_text_div'>
                                    <h2 className='titlled'>Convinience for All</h2>
                                    <p className='text'>At OTOWALA, your safety and comfort are our highest priorities. We are dedicated to continuously improving our safety protocols to guarantee that every ride with us is enjoyable and secure.</p>
                                   <Link to={"/aboutus"}><Common_Button text="KNOW MORE" className="knowmorebutton" /></Link> 
                                </div>
                            </Col>
                            <Col lg={6} className='mb-3'>
                                <div className='convenveimages'>
                                    <Row>
                                        <Col sm={7} className='mb-3'>
                                            <div className='conenve_big_div '>
                                                <img src={process.env.PUBLIC_URL + "/assets/images/homepage/bookgirl.png "} className="callboy" />
                                            </div>
                                        </Col>
                                        <Col sm={5}>
                                            <div className='convenveimages'>
                                                <div className='conenve_small_div'>
                                                    <img src={process.env.PUBLIC_URL + "/assets/images/homepage/callboy.png"} className="boogril" />
                                                </div>
                                                <div className='conenve_blank_div'>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>

                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </section>

        </>
    )
}

export default Conveince